import Link from "next/link";
import Image from "next/image";

import { useState, useContext } from "react";
import { FirebaseContext } from "../contexts/FirebaseContext";

const Navbar = () => {
  const { currentUser, logOut } = useContext(FirebaseContext);
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };

  const onLogOut = async () => {
    await logOut();
  };

  return (
    <nav className="sticky top-0 z-[999] w-full justify-between border-b border-zinc-300 bg-white font-kr text-zinc-700 selection:bg-zinc-700 selection:text-zinc-100">
      <div className="flex items-center justify-between px-6 py-4 md:px-8 md:py-2">
        <Link href="/">
          <a className="">
            <img
              src="/assets/ocena_logo.png"
              className="h-7"
              alt="ocena logo"
            />
          </a>
        </Link>
        <div className="hidden items-center space-x-3 md:flex">
          <div className="flex space-x-2">
            {/* <Link href="/pricing">
              <a className="rounded-full px-4 py-3   text-sm font-medium text-zinc-800 transition-all hover:bg-primary/20">
                가격정책
              </a>
            </Link> */}
            <a
              href="https://blog.ocena.app"
              target="_blank"
              rel="noopener noreferrer"
              className="border-2 border-white px-2 py-2 text-sm font-medium decoration-primary/60 decoration-[3px] underline-offset-4 transition-all  hover:underline "
            >
              블로그
            </a>
            <Link href="/contact">
              <a className="border-2 border-white px-2 py-2 text-sm font-medium decoration-primary/60 decoration-[3px] underline-offset-4 transition-all  hover:underline ">
                문의하기
              </a>
            </Link>
            {currentUser ? (
              <Link href="/dashboard">
                <a className="border-2 border-white px-2 py-2 text-sm font-medium decoration-primary/60 decoration-[3px] underline-offset-4 transition-all  hover:underline ">
                  대시보드 홈
                </a>
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div className="flex space-x-2">
            {currentUser ? (
              <div>
                <button
                  onClick={onLogOut}
                  className="border-2 border-white px-4 py-3 text-sm font-medium decoration-primary/60 decoration-[3px] underline-offset-4 transition-all  hover:underline "
                >
                  로그아웃
                </button>
              </div>
            ) : (
              <>
                <Link href="/login">
                  <a className=" border-2 border-white px-4 py-3 text-sm font-medium decoration-primary/60 decoration-[3px] underline-offset-4 transition-all  hover:underline ">
                    로그인
                  </a>
                </Link>
              </>
            )}
            {/* <Link href="/pdf">
              <a className="rounded-full border border-primary bg-primary px-5 py-3   text-sm font-medium  text-white transition-all hover:border-white ">
                견적서 만들기
              </a>
            </Link> */}
            <Link href="/contact">
              <button className="rounded-full border-2 border-zinc-300 px-4 py-3 text-sm font-bold transition-all  hover:bg-zinc-200">
                데모 요청하기
              </button>
            </Link>
            <Link href="/launch">
              <button className="rounded-full border-2 border-primary/70 px-5 py-3 text-sm font-bold transition-all  hover:border-primary/10 hover:bg-primary/50">
                출시 사전신청
              </button>
            </Link>
          </div>
        </div>
        {active === false && (
          <button
            onClick={toggleMenu}
            className="flex cursor-pointer items-center focus:outline-hidden md:hidden"
          >
            <Image
              height={24}
              width={24}
              src="/assets/menu_icon.png"
              alt="menu"
            />
          </button>
        )}
        {active && (
          <button
            onClick={toggleMenu}
            className="flex cursor-pointer items-center focus:outline-hidden md:hidden"
          >
            <Image
              height={20}
              width={20}
              src="/assets/close.png"
              alt="close menu"
            />
          </button>
        )}
      </div>

      {active && (
        <div className="top-[60px] w-full items-center md:hidden">
          <div className="flex h-screen w-full flex-col items-center space-y-10 self-center bg-zinc-100 py-12 ">
            <div className="flex h-full flex-col space-y-4">
              {/* <Link href="/pricing">
                <a
                  onClick={toggleMenu}
                  className="self-center rounded-full px-5 py-3   text-lg font-medium text-zinc-800 transition-all hover:bg-primary/20"
                >
                  가격정책
                </a>
              </Link> */}
              <a
                onClick={toggleMenu}
                href="https://blog.ocena.app"
                target="_blank"
                rel="noopener noreferrer"
                className="self-center rounded-full px-5 py-3   text-lg font-medium text-zinc-800 transition-all hover:bg-primary/20"
              >
                블로그
              </a>
              <Link href="/contact">
                <a
                  onClick={toggleMenu}
                  className="self-center rounded-full px-5 py-3   text-lg font-medium text-zinc-800 transition-all hover:bg-primary/20"
                >
                  문의하기
                </a>
              </Link>
              {currentUser ? (
                <div className="flex flex-col space-y-4">
                  <Link href="/dashboard">
                    <a
                      onClick={toggleMenu}
                      className="self-center rounded-full px-5 py-3   text-lg font-medium text-zinc-800 transition-all hover:bg-primary/20"
                    >
                      대시보드 홈
                    </a>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="mt-auto mb-10 flex h-full flex-col space-y-6">
              {/* {currentUser ? (
                <button
                  onClick={() => {
                    logOut();
                    toggleMenu();
                  }}
                  className="flex w-48 justify-center rounded-full  border border-zinc-200 bg-zinc-100 px-7 py-6   font-medium leading-normal text-zinc-500 transition-all hover:border-white"
                >
                  로그아웃
                </button>
              ) : (
                <>
                  <Link href="/login">
                    <a
                      onClick={toggleMenu}
                      className="flex w-48 justify-center rounded-full border border-zinc-600 px-7 py-6 font-medium leading-normal text-zinc-800 transition-all hover:border-white hover:bg-zinc-200"
                    >
                      로그인
                    </a>
                  </Link>
                </>
              )} */}
              <Link href="/contact">
                <button className="w-40 rounded-full border-2 border-zinc-300 px-6 py-5 font-bold transition-all  hover:bg-zinc-200">
                  데모 요청하기
                </button>
              </Link>
              <Link href="/launch">
                <button className="w-40 rounded-full border-2 border-primary/70 px-6 py-5  font-bold transition-all  hover:border-primary/10 hover:bg-primary/50">
                  출시 사전신청
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
